import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router/index' //引入
import dataV from '@jiaminghi/data-view'
import * as echarts from 'echarts'
import { Lazyload } from 'vant';
import 'amfe-flexible'
import 'amfe-flexible/index.js'
const app = createApp(App)
app.config.globalProperties.$echarts = echarts
app.use(store).use(router).use(dataV).use(echarts).use(Lazyload, {
  lazyComponent: true,
  loading: require('./assets/singran.png'),
  attempt: 1
}).mount('#app')
